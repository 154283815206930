import React from "react";
import ImageOffice from "./assets/1.jpeg";

import {
  ContactContainer,
  TextContainer,
  ImageContainer,
  Title,
  Line,
  Text,
  TextContent,
  OfficeImage,
} from "./styles";

const OfficeStaff = () => {
  return (
    <ContactContainer>
      <TextContainer>
        <TextContent>
          <Title>HOSPICE LEVELS OF CARE</Title>
          <Line />
          <Text>
            Routine Home Care: care provided in a residential setting or
            patient’s home.
          </Text>
          <Text>
            Inpatient Care: short-term acute care in a hospital or nursing
            facility.
          </Text>
          <Text>Respite Care: short-term care for the caregiver’s break.</Text>
          <Text>
            Continuous Care: short-term care at the patient’s residence during a
            crisis.
          </Text>
        </TextContent>
      </TextContainer>
      <ImageContainer>
        <OfficeImage
          src={ImageOffice}
          alt="smiling woman holding hands with companion"
        />
      </ImageContainer>
    </ContactContainer>
  );
};

export default OfficeStaff;
