import React from "react";
import ImageOffice from "./assets/2.jpg";

import {
  ContactContainer,
  ContactContainerMobile,
  TextContainer,
  ImageContainer,
  Title,
  Line,
  Text,
  TextContent,
  OfficeImage,
} from "./styles";

const SecondInfoContainer = () => {
  return (
    <>
      <ContactContainer>
        <ImageContainer>
          <OfficeImage
            src={ImageOffice}
            alt="nurse caring for an elderly woman"
          />
        </ImageContainer>
        <TextContainer>
          <TextContent>
            <Title>PAIN AND SYMPTOM MANAGEMENT</Title>
            <Line />
            <Text>
              Our number one goal is to optimize and maximize our patient’s
              quality of life during their end-of-life journey. In collaboration
              with the patient and family, our team of experts will create and
              implement a plan of care that is patient specific and tailored to
              alleviate pain and any other symptoms.
            </Text>
          </TextContent>
        </TextContainer>
      </ContactContainer>
      <ContactContainerMobile>
        <TextContainer>
          <TextContent>
            <Title>PAIN AND SYMPTOM MANAGEMENT</Title>
            <Line />
            <Text>
              Our number one goal is to optimize and maximize our patient’s
              quality of life during their end-of-life journey. In collaboration
              with the patient and family, our team of experts will create and
              implement a plan of care that is patient specific and tailored to
              alleviate pain and any other symptoms.
            </Text>
          </TextContent>
        </TextContainer>
        <ImageContainer>
          <OfficeImage
            src={ImageOffice}
            alt="nurse caring for an elderly woman"
          />
        </ImageContainer>
      </ContactContainerMobile>
    </>
  );
};

export default SecondInfoContainer;
