import styled from "styled-components";
import { COLORS } from "../../styles/globals/colors";
import { TABLET_768 } from "../../styles/globals/sizes";

export const OurCredentialsContainer = styled.div`
  width: 100%;
  padding: 35px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.redTitle};
  gap: 20px;

  @media (max-width: ${TABLET_768}px) {
    padding: 66px 20px 76px;
    flex-direction: column;
    text-align: center;
  }
`;

export const Image = styled.img`
  width: 198px;
  height: 198px;
`;

export const Text = styled.div`
  max-width: 605px;
  font: normal normal 400 30px/36px Roboto;
  color: ${COLORS.white};

  @media (max-width: ${TABLET_768}px) {
    font-size: 28px;
    line-height: 38px;
  }
`;
