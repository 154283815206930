import styled from "styled-components";
import {
  DESKTOP_1200,
  MOBILE_460,
  TABLET_600,
  TABLET_800,
  TABLET_992,
} from "../../styles/globals/sizes";

export const MainContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  padding-inline: 10%;
  background: #ec4746;
  box-sizing: border-box;
  overflow-x: hidden;
  padding-top: 45px;
  @media (max-width: ${DESKTOP_1200}px) {
    padding-inline: 4%;
    padding-left: 10%;
    height: auto;
  }

  @media (max-width: ${TABLET_992}px) {
    padding-left: 6%;
  }

  @media (max-width: ${TABLET_800}px) {
    padding-left: 5%;
  }

  @media (max-width: ${MOBILE_460}px) {
    padding-inline: 2%;
    padding-left: 4%;
    padding-bottom: 45px;
  }
`;

export const TextButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`;

export const TitleButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
  flex-wrap: wrap;

  @media (max-width: ${TABLET_992}px) {
    margin-bottom: 0;
    margin-left: 0;
  }

  @media (max-width: ${TABLET_600}px) {
    flex-direction: row;
    align-items: flex-start;
  }
`;

export const ImageContainer = styled.div`
  margin-right: 20px;
  flex-shrink: 0;
  position: relative;

  @media (max-width: ${TABLET_992}px) {
    margin-bottom: 10px;
  }
  @media (max-width: ${TABLET_600}px) {
    margin-right: 10px;
    margin-left: 5px;
  }
`;

export const TitleTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 80%;
  margin-right: 60px;

  @media (max-width: ${TABLET_600}px) {
    margin-right: 0;
  }
`;

export const Title = styled.h2`
  margin: 0 0 10px 0;
  font: normal normal normal 34px/45px Roboto;
  font-weight: 500;
  font-size: 34px;
  font-family: Roboto;
  color: #ffffff;
`;

export const Button = styled.button`
  width: 270px;
  background-color: white;
  color: #e0281a;
  border: none;
  border-radius: 24px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  align-self: flex-start;
  margin-top: 5px;
  @media (max-width: ${TABLET_992}px) {
    display: none;
  }
`;

export const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const SendTo = styled.p`
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: 500;
  font-family: Roboto;
  color: #ffffff;
`;

export const ImgStyles = styled.img`
  width: 50px;
  height: 50px;
  @media (max-width: ${TABLET_800}px) {
    width: 30px;
    height: 30px;
  }
`;

export const FaxNumber = styled.p`
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: 400;
  font-family: Roboto;
  color: #ffffff;
  @media (max-width: ${TABLET_992}px) {
    display: none;
  }
`;

export const ButtonContainer = styled.a`
  @media (max-width: ${DESKTOP_1200}px) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10%;
  }
  @media (max-width: ${TABLET_992}px) {
    display: none;
  }
`;

export const ButtonMobileContainer = styled.div`
  display: none;
  @media (max-width: ${TABLET_992}px) {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
  }
`;

export const TextContainerMobile = styled(TextContainer)`
  display: none;
  @media (max-width: ${TABLET_992}px) {
    display: flex;
    width: 100%;
  }
`;

export const FaxNumberMobile = styled(FaxNumber)`
  display: none;
  @media (max-width: ${TABLET_992}px) {
    display: block;
    font-size: 18px;
    br {
      margin-bottom: 5px;
      display: block;
      content: "";
    }
  }
`;

export const ButtonMobile = styled(Button)`
  display: none;

  @media (max-width: ${TABLET_992}px) {
    display: block;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 10px;
    width: 50vw;
  }
  @media (max-width: ${TABLET_600}px) {
    width: 80vw;
  }
`;
